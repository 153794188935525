import PropTypes from "prop-types"
import { useT } from "react-polyglot-hooks"
import styled from "styled-components"
import Typography from "components/styled/Typography"
import { color } from "helpers/styled"

const Text = styled(Typography)`
  display: inline-block;
  margin-top: 5px;
  padding: 1px 5px;
  font-style: italic;
  background-color: ${color("skyBlue")};
  border-radius: 3px;

  @media (max-width: 991.9px) {
    margin: 0;
    padding: 0 5px;
    font-size: 11px;
    line-height: 16px;
  }
`

function DownPayment({ downPayment }) {
  const t = useT()

  return (
    <Text color="white">
      {downPayment ? t("common.down_payment", { downPayment }) : t("common.without_down_payment")}
    </Text>
  )
}

DownPayment.propTypes = {
  downPayment: PropTypes.string.isRequired
}

export default DownPayment
